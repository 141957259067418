import React, { Component } from 'react'
// import Link from '../utils/link'
import { Link } from "gatsby"

import Logo from '../assets/images/logo.svg'
import LogoSmall from '../assets/images/logo-mark.svg'

class Header extends Component {

  state = {
    offCanvas: false
  }

  _hideOffCanvas = () => {
    this.setState({ offCanvas: false })
  }

  _toggleOffCanvas = () => {
    this.setState({ offCanvas: !this.state.offCanvas })
  }

  render() {

    let { offCanvas } = this.state

    let props = {
      onClick: this._hideOffCanvas,
      activeClassName: 'active'
    }

    return (
      <>
        <header className='header'>
          <div className='header__inner'>

            <div className='header__col'>
              <div className='header__logo'>
                <Link to='/' {...props}>
                  <img className='large' src={Logo} alt='Foster Lomas' />
                  <img className='small' src={LogoSmall} alt='Foster Lomas' />
                </Link>
              </div>
            </div>

            <div className='header__col'>
              <nav className='header__nav'>
                <ul>
                  <li><Link to='/projects' {...props}>Projects</Link></li>
                  <li><Link to='/studio' {...props}>Studio</Link></li>
                  <li><Link to='/people' {...props}>People</Link></li>
                  <li><Link to='/journal' {...props}>Journal</Link></li>
                </ul>
              </nav>

              <button onClick={this._toggleOffCanvas} className={ offCanvas ? 'header__hamburger active' : 'header__hamburger' }>
                <span className='lines'></span>
              </button>
            </div>
          </div>
        </header>
        <div className={`off-canvas ${ offCanvas && 'off-canvas--active' }`}>
          <div className='off-canvas__inner'>
            <nav className='off-canvas__nav'>
              <ul>
                <li><Link to='/' {...props}>Home</Link></li>
              </ul>
            </nav>
          </div>
        </div>
      </>
    )
  }
}

export default Header
