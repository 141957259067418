import React, { Component } from 'react'

import Link from '../utils/link'

function encode(data) {
  return Object.keys(data)
    .map(key => encodeURIComponent(key) + "=" + encodeURIComponent(data[key]))
    .join("&")
}

class Form extends Component {
  state = {
    result: '',
    msg: ''
  }

  handleChange = e => {
    this.setState({ [e.target.name]: e.target.value })
  }

  handleSubmit = e => {
    e.preventDefault()

    if (!this.refs.form.checkValidity()) return false

    fetch("/", {
      method: "POST",
      headers: { "Content-Type": "application/x-www-form-urlencoded" },
      body: encode({
        "form-name": this.refs.form.getAttribute("name"),
        ...this.state,
      }),
    })
      .then(() => {
        this.setState({ result: "success" })
      })
      .catch(error => this.setState({ result: "fail", msg: error }))
  }

  render() {

    let props = {
      ref: "form",
      name: "Newsletter Signup",
      className: "form",
      onSubmit: this.handleSubmit,
      "data-netlify": "true",
      "data-netlify-honeypot": "bot-field",
    }

    if (this.state.result === "success")
      return (
        <p className="popup__success">
          Thanks for subscribing.
        </p>
      )

    return (
      <form {...props}>
        <div className="form__row form__row--quad">
          {/* <input
            type="text"
            name="first_name"
            placeholder="First Name"
            onChange={this.handleChange}
            required
          />
          <input
            type="text"
            name="last_name"
            placeholder="Last Name"
            onChange={this.handleChange}
            required
          /> */}
          <input
            type="email"
            name="email"
            placeholder=""
            onChange={this.handleChange}
            required
          />
          <div className="form__row">
            <div className="form__checkbox">
              <input 
                type="checkbox" 
                name="subscribe"
                required="required"
                id="subscribe"
                onChange={this.handleChange}
              />
              <label htmlFor='subscribe'>
              I accept the Foster Lomas <Link to='/privacy-policy/'>Privacy & Policy</Link>
              </label>
            </div>
          </div>
          <div className="btn-container">
            <button type="submit" className="btn">
              Subscribe
            </button>
          </div>
        </div>
      </form>
    )
  }
}

class NewsletterPopup extends Component {

  state = {
    show: false,
    result: '',
    msg: '',
  }

  hidePopup = () => {
    this.setState({ show: false });
  }

  render() {
    // let { popup } = this.props.data.wp.siteSettings.acf

    let { show } = this.state
    return (
      <div className={ show ? 'popup popup--newsletter active' : 'popup popup--newsletter'}>
        <div className="popup__wrapper">
          {/* <img className='popup__bg' loading='lazy' src={popup.image?.sourceUrl} alt='Newsletter' /> */}
          <div className='popup__header'>
            {/* <button
              type='button'
              className='popup__close'
              onClick={this.hidePopup}
            >
              Close
            </button> */}
          </div>
          <div className='popup__form'>
            <Form />
          </div>
        </div>
      </div>
    )
  }
}

export default NewsletterPopup
