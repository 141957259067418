import React, { useState, useEffect } from 'react';
import Link from '../utils/link';
import NewsletterForm from '../components/newsletter-form';

const Footer = ({ isModalOpen, openModal, closeModal }) => {
  
  useEffect(() => {
    if (!isModalOpen) {
      document.body.classList.add('modal-open');
      document.querySelector('.header').classList.add('active');
      document.querySelector('main').classList.add('active');
    } else {
      document.body.classList.remove('modal-open');
      document.querySelector('.header').classList.remove('active');
      document.querySelector('main').classList.remove('active');
    }
  }, [isModalOpen]);

  return (
    <>
      <div className={`newsletter__form ${isModalOpen ? 'active' : 'notactive'}`}>
        <div className='newsletter__form-wrapper'>
          <div className='newsletter__form-container'>
            <div className='newsletter__header'>
              <h4>Sign up to our Journal</h4>
            </div>
            <NewsletterForm />

            <button
              className='btn btn--cancel'
              type='button'
              onClick={closeModal}
            >
              <svg width='21' height='22' viewBox='0 0 21 22' fill='none' xmlns='http://www.w3.org/2000/svg'>
                <line x1='0.353553' y1='0.649376' x2='20.3536' y2='20.6494' stroke='black' />
                <line x1='20.3536' y1='1.35648' x2='0.353554' y2='21.3565' stroke='black' />
              </svg>
            </button>
          </div>
        </div>
      </div>

      <footer className='footer'>
        <div className='footer__inner'>
          <div className='footer__col'>
            <ul className='footer__nav'>
              <li><Link to='/studio/#contact'>Contact Us</Link></li>
              <li><button className='btn' onClick={openModal}>Subscribe to our Journal</button></li>
              <li><Link to='https://www.instagram.com/fosterlomas'>Instagram</Link></li>
              {/* <li><Link to='https://twitter.com/fosterlomas'>Twitter</Link></li> */}
              <li><Link to='https://www.pinterest.co.uk/fosterlomas/_created'>Pinterest</Link></li>
              <li><Link to='https://www.linkedin.com/company/foster-lomas'>LinkedIn</Link></li>
            </ul>
          </div>

          <div className='footer__col'>
            <ul className='footer__nav-left'>
              <li>
                <span className='footer__copyright'>
                  &copy; 2023 Foster Lomas. All rights reserved
                </span>
              </li>
              <li><Link to='/privacy-policy'>Privacy Policy</Link></li>
            </ul>
          </div>
        </div>
      </footer>
    </>
  );
};

export default Footer;
